.wrapperinfo{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:center;
    gap: 1.5rem;
    border-radius: 10px;
    background:#E0D9D6;
    height: 71vh;
    
}
.account{
    display: flex;
    align-items: center;
    gap: 2rem;
    justify-content: space-between;
    
}  
.account p{
    color: var(--mainColor);
    font-size: 1.5rem;
    font-weight: bold;
}

.current{
    display: flex;
    align-items: center;
    gap: 2rem;
    padding-left: 0;
    justify-content:space-between;
    
}

.current p{
    color: var(--mainColor);
    font-size: 1.5rem;
    font-weight: bold;
    left: -10px;
}
.desc1,.desc2,.desc3{
    color: var(--mainColor);
    font-weight: bold;
    font-size: 0.8rem;

}
.title{
    font-weight: bold;
    font-size: 1.5rem;
}

@media(max-width:760px){
    .account,.current{

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;

    }

    .account p,.current p{
        font-size: 1rem;
    }
    .title{
        font-size: 1rem;
    }
    
}