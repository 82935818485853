footer{
    width: 100%;
    height: 200px;
    position: relative;
    background: var(--mainColor);
    display: flex;
    justify-content: space-around;
}
h1,h2{
    color: white;
}
.f-container{
    display: flex;
    align-items: center;
   justify-content: space-between;
    
}

.follow{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
}
.icons p{
    color: white;
    font-size: 1.2rem;
}
.message{
    color: white;
    background-color: #0b4f6c;
    border: none;
    height: 20px;

}