.rapper{
   
    width: 100%;
   
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    justify-content: center;
    align-items: center;
    position: relative;
    
   
}
.gradient{
    height: 100%;
    width: 100%;
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.4;
}
img{
    object-fit: cover;
    height: 100%;
    width: 100%;
    display: block;
}
 .text{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 4rem;
  
   
}
.text p{
    margin-top: 2.2rem;
    color: white;
    font-size: 3rem;
    font-weight: bold;
    
}
.text .quran1{
    color: white;
    font-size: 1.5rem;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
}
@media (max-width:768px){
    .text p{
        font-size: 1.5rem;
      
        
    }

}